import * as React from "react"
import PropTypes from "prop-types"
import Layout from "../components/Layout/Layout"
import SeoHead from "../components/Scripts/SeoHead"

const NotFoundPage = props => {
  const { pageContext } = props
  const { homeBreadcrumbTitle, headerImages, seoData } = pageContext
  return (
    <Layout
      seoData={seoData}
      headerImages={headerImages}
      homeBreadcrumbTitle={homeBreadcrumbTitle}
    >
      <h2
        className="text-greenDark text-[48px] mb-[35px] font-light font-Calibri"
        style={{ letterSpacing: "normal" }}
      >
        The page you are looking for cannot be found.
      </h2>
      <p
        className="text-[#212529] font-normal text-[16px]"
        style={{ letterSpacing: "0.53px" }}
      >
        Sorry about that!
      </p>
    </Layout>
  )
}

export const Head = () => <SeoHead title="404: Not Found" />

export default NotFoundPage

NotFoundPage.propTypes = {
  pageContext: PropTypes.shape({
    homeBreadcrumbTitle: PropTypes.string,
    headerImages: PropTypes.object,
    seoData: PropTypes.object,
  }),
}
